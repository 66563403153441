.footer {
  bottom: 0;
  background-color: #2d2d2d;
  width: 100%;
  height: fit-content;
  padding: 0px 0px 200px 0px;
  margin: 0;
  border-top: solid 2px #e3a44e;
}
.footer-component {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 30px 50px 0px 50px;
}
.footer-address i {
  color: #e3a44e;
  margin-right: 4px;
}
.footer-img {
  margin-top: 20px;
  height: 120px;
}
#cp-rights {
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 400;
  color: #8c8c8c;
  text-align: center;
  margin-top: 10px;
}
.footer-component a,
li,
p {
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 5px;
}
.footer-component :hover {
  color: #e3a44e;
}
.footer-address {
  display: flex;
  flex-direction: column;
}
.footer-links,
.footer-products a {
  font-size: 1.5rem;
}
.footer-address a {
  margin-bottom: 5px;
}
.socials {
  display: flex;
  width: 45%;
  justify-content: space-between;
}
.foot-prod-row {
  margin-left: 10px;
  display: flex;
}
.foot-prod-col {
  display: flex;
  flex-direction: column;
}
.foot-prod-col-1 {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.foot-prod-col a {
  font-size: 1rem;
  margin: 0;
}
.foot-prod-col-1 a {
  font-size: 1rem;
  margin: 0;
}
@media only screen and (max-width: 400px) {
  .footer {
    height: fit-content;
    padding: 0px 0px 15px 0px;
  }
  .footer-component {
    padding: 10px 5px 5px 5px;
  }
  .footer-links,
  .footer-products {
    display: none;
  }
  .footer-address p {
    display: none;
  }
  .footer-address {
    margin-top: 20px;
  }
  .footer-img {
    height: 70px;
  }
  #cp-rights {
    font-size: 0.9rem;
  }
  .footer-email,
  .footer-phone {
    font-size: 0.78rem;
  }
  .socials svg {
    width: 25px;
  }
}
@media only screen and (min-width: 400px) {
  .footer {
    height: fit-content;
    padding: 0px 0px 20px 0px;
  }
  .footer-component {
    padding: 10px 5px 5px 5px;
  }
  .footer-links,
  .footer-products {
    display: none;
  }
  .footer-address p {
    display: none;
  }
  .footer-address {
    margin-top: 20px;
  }
  .footer-img {
    height: 90px;
  }
  #cp-rights {
    font-size: 1rem;
  }
  .footer-email,
  .footer-phone {
    font-size: 1.1rem;
  }
  .socials {
    width: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 30%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  .footer-products {
    display: none;
  }
  #cp-rights {
    font-family: monospace;
    font-size: 1.2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    margin: 0px;
    width: fit-content;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 1.5rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .foot-prod-col a {
    font-size: 0.5rem;
    margin: 0;
  }
  .foot-prod-col-1 a {
    font-size: 0.5rem;
  }
  .footer-links {
    width: 20%;
  }
  .footer-products {
    width: 20%;
  }
  .footer-links-text a {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 900px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px 10px 0px 10px;
    align-items: center;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 20%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  
  #cp-rights {
    font-family: monospace;
    font-size: 1.2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    align-self: flex-start;
    margin: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 1.5rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
  .foot-prod-col a {
    font-size: 1.1rem;
    margin: 0;
  }
  .foot-prod-col-1 a {
    margin: 0px;
    font-size: 1.1rem;
  }
  .footer-links {
    width: 15%;
  }
  .footer-products {
    align-self: flex-start;
    width: 40%;
  }
  .footer-links-text a {
    font-size: 1.5rem;
  }
  .prod-false{
    display: none;
  }
}
@media only screen and (min-width: 1500px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px 10px 0px 10px;
    align-items: center;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 20%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  
  #cp-rights {
    font-family: monospace;
    font-size: 2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.95rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    align-self: flex-start;
    margin: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 2.7rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
  }
  .foot-prod-col a {
    font-size: 1.8rem;
    margin: 1px;
  }
  .foot-prod-col-1 a {
    margin: 1px;
    font-size: 1.8rem;
  }
  .footer-links {
    width: 15%;
  }
  .footer-products {
    align-self: flex-start;
    width: 40%;
  }
  .footer-links-text a {
    font-size: 2.7rem;
  }
  .prod-false{
    display: none;
  }
  .socials svg {
    width: 55px;
    height: 55px;
  }
}