.top-image {
  top: 0;
  width: 100%;
  height: 300px;
  position: fixed;
}
.top-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  filter: brightness(50%);
}
.top-image-container {
  height: 300px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 80px;
  flex-direction: column;
}
.top-image-container h2 {
  align-self: center;
  color: white;
  font-family: "Open Sans";
  font-size: 2.6vw;
  font-weight: 500;
  border-bottom: 3px solid #e3a44e;
  width: 18vw;
}
.top-image-container p {
  margin: 10px;
  align-self: center;
  font-size: 1.5vw;
  font-weight: 500;
  width: 70vw;
  text-align: center;
}
.product-container {
  width: 100%;
  height: 2600px;
  background-color: #efe9e3;
  top: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
}
.product-wood-container {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-pack-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-door-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-frames-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-container h3 {
  font-family: "Open Sans";
  text-align: center;
  font-weight: 600;
  height: 40px;
  width: 400px;
  color: white;
  background-color: #edb76d;
  border-radius: 7px;
  font-size: 2vw;
}
.product-wood-grid {
  margin-top: 50px;
  display: grid;
  row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto;
}
.product-pack-grid {
  margin-top: 50px;
  display: grid;
  row-gap: 30px;
  grid-template-columns: auto auto auto;
}
.product-frame-grid {
  margin-top: 50px;
  display: grid;
  row-gap: 30px;
  grid-template-columns: auto auto auto;
}
.product-card {
  background-color: white;
  height: 400px;
  width: 400px;
  box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}
.product-footer {
  bottom: 0;
}
.product-card img {
  width: 100%;
  height: 85%;
  object-fit: cover;
  transition: 0.5s ease;
}
.product-card h2 {
  padding: 5px;
  text-align: center;
  color: #5a4d4d;
  font-family: "Open Sans";
  font-weight: 500;
}
.product-card img:hover {
  opacity: 0.5;
}
.button-product {
  position: absolute;
  align-self: center;
  border: 2px solid #edb76d;
  z-index: 1;
  top: 1;
}
@media only screen and (max-width: 400px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 1.8rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 0.9rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 1.8rem;
  }
  .product-wood-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-pack-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-card {
    background-color: white;
    height: 300px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 400px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
  }
  .product-wood-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-pack-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 650px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1.3rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
  }
  .product-wood-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-pack-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 1100px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1.3rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: fit-content;
    width: fit-content;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-wood-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 30px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-pack-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.5rem;
    padding: 5px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 1500px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 3rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: fit-content;
  }
  .top-image-container p {
    margin: 15px;
    align-self: center;
    font-size: 1.8rem;
    font-weight: 400;
    width: 80%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: fit-content;
    background-color: #efe9e3;
    top: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: fit-content;
    padding: 40px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: fit-content;
    width: fit-content;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 3rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-wood-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 30px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-pack-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-card {
    background-color: white;
    height: 40em;
    width: 30vw;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 2.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
