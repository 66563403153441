.navbar {
  font-family: sans-serif;
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 55px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.navbar-link {
  margin: 20px;
  text-decoration: none;
  color: #615e5e;
  border-bottom: solid 2px transparent;
  cursor: pointer;
}
.navbar-link--active a {
  border-bottom: 2px solid #e3a44e;
}
.navbar-link--active--product {
  border-bottom: 2px solid #e3a44e;
}
.navbar-link .col:hover {
  color: #e3a44e;
}
.nav-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar a {
  font-size: 1.2rem;
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  color: #615e5e;
}

.end a:hover {
  color: #e3a44e;
}
.logo {
  float: left;
  height: 48px;
}
.mark {
  height: 30px;
  width: 30px;
}

.navbar-link-contact {
  margin: 10px;
  background-color: #f7e4ca;
  border-radius: 10px;
  padding: 3px;
}
.end {
  display: flex;
  padding-left: 40px;
  padding-right: 10px;
  align-content: center;
}
.hamburger {
  display: none;
}
@media only screen and (max-width: 400px) {
  .navbar {
    flex: row;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.1rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 0.7rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 4px;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out width;
  }
  .product-toggle-bg h3 {
    font-size: 0.9rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 400px) {
  .navbar {
    flex: row;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.2rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.3rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 1rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    font-size: rem;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 1.02rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out width;
  }
  .product-toggle-bg h3 {
    font-size: 1.12rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 1;
  }
}
@media only screen and (min-width: 768px) {
  .navbar {
    flex: row;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.3rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.4rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 1.2rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    font-size: rem;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out;
  }
  .product-toggle-bg h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 1;
  }
}
@media only screen and (min-width: 800px) {
  .navbar {
    font-family: sans-serif;
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 55px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-link {
    margin: 15px;
    text-decoration: none;
    color: #615e5e;
    border-bottom: solid 2px transparent;
    cursor: pointer;
  }
  .navbar-link--active a {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link--active--product {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link .col:hover {
    color: #e3a44e;
  }
  .nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .navbar a {
    font-size: 1.2rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    color: #615e5e;
  }
  
  .end a:hover {
    color: #e3a44e;
  }
  .logo {
    float: left;
    height: 48px;
  }
  .mark {
    height: 30px;
    width: 30px;
  }
  
  .navbar-link-contact {
    margin: 10px;
    background-color: #f7e4ca;
    border-radius: 10px;
    padding: 3px;
  }
  .end {
    display: flex;
    padding-left: 40px;
    padding-right: 10px;
    align-content: center;
  }
  .hamburger {
    display: none;
  }
  .toggle-container{
    display: none;
  }
  .dropdown-content h5 {
    color: #4d4d4d;
    font-size: 1.2rem;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .dropdown-content a {
    width: 100%;
    padding: 5px;
    font-size: 1.08rem;
    font-family: "Open Sans";
    font-weight: 400;
    border-radius: 5px;
    transition: 0.2s ease all;
  }
  .dropdown-content {
    display: none;
    align-self: center;
    margin-left: -50px;
    margin-top: 15px;
    position: absolute;
    width: 300px;
    height: fit-content;
    min-width: 160px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: 0.2s 1s;
  }
}
@media only screen and (min-width: 1500px) {
  .navbar {
    font-family: sans-serif;
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 75px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-link {
    margin: 15px;
    text-decoration: none;
    color: #615e5e;
    border-bottom: solid 2px transparent;
    cursor: pointer;
  }
  .navbar-link--active a {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link--active--product {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link .col:hover {
    color: #e3a44e;
  }
  .nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .navbar a {
    font-size: 1.85rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    color: #615e5e;
  }
  
  .end a:hover {
    color: #e3a44e;
  }
  .logo {
    float: left;
    height: 60px;
  }
  .mark {
    height: 40px;
    width: 40px;
  }
  
  .navbar-link-contact {
    margin: 10px;
    background-color: #f7e4ca;
    border-radius: 10px;
    padding: 3px;
  }
  .end {
    display: flex;
    padding-left: 40px;
    padding-right: 10px;
    align-content: center;
  }
  .hamburger {
    display: none;
  }
  .toggle-container{
    display: none;
  }

  .dropdown-content h5 {
    color: #4d4d4d;
    font-size: 1.7rem;
    font-family: "Open Sans";
    font-weight: 500;
  }

  .dropdown-content {
    display: none;
    align-self: center;
    margin-left: -50px;
    margin-top: 15px;
    position: absolute;
    width: 400px;
    height: fit-content;
    min-width: 160px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: 0.2s 1s;
  }
  .dropdown-content a {
    width: 100%;
    padding: 5px;
    font-size: 1.45rem;
    font-family: "Open Sans";
    font-weight: 400;
    border-radius: 5px;
    transition: 0.2s ease all;
  }
}
.drop-down {
  position: relative;
  display: inline-block;
  margin: 15px;
}

.drop-down:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  transition-delay: 1s;
}
.dropdown-content {
  padding: 10px 0px 10px 30px;
}

a {
  text-decoration: none;
}
.toggle-not-active {
  display: none;
}
.useability {
  margin-top: -25px;
  height: 30px;
  width: 40%;
  background-color: transparent;
}

.drop-link li {
  margin: 3px;
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  transition: 0.2s ease all;
}
.drop-link li :hover {
  background-color: #f7e4ca;
}
.drop-link {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
/*

.triangle {
  margin-top: -30px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border-bottom: solid 20px white;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}*/
