.canvas-about {
  background-color: #efe9e3;
  width: 100%;
  height: 610px;
}
.about-container {
  height: 610px;
  width: 100%;
  position: absolute;
  top: 0;
}
.about-box {
  position: absolute;
  float: right;
  right: 0;
  bottom: 0;
  width: 80vw;
  height: 450px;
  background-color: white;
  text-align: center;
  padding: 20px 0px 0px 0px;
}
.about-container-1 {
  width: 100%;
  height: 100vh;
}
.about-box h3 {
  font-family: "Open Sans";
  font-size: 2.5vw;
  font-weight: 600;
  margin: 40px 0px 0px 45vw;
  width: 15vw;
  color: #4f4f4f;
  border-bottom: 2px solid #e3a44e;
}
.history {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.history h3 {
  font-family: "Open Sans";
  font-size: 2.5vw;
  font-weight: 600;
  width: 15vw;
  color: #4f4f4f;
  border-bottom: 2px solid #e3a44e;
}
.history p {
  margin: 40px 20px 0px 20px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  color: #4f4f4f;
}
.about-section {
  width: 100%;
  height: 100vh;
}
.about-section-canvas {
  width: 100%;
  height: 100%;
  background-color: #efe9e3;
}
.about-section-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.about-section-box {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 450px;
  width: 70%;
  background-color: white;
  padding: 40px 60px 0px 70px;
  font-size: 2vw;
}
.about-section-box h3 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  margin-top: 20px;
  margin-left: 20vw;
  color: #434343;
  border-bottom: 2px solid #e3a44e;
  width: 15vw;
}
.about-section-box p {
  font-size: 1.5rem;
  margin-top: 110px;
  color: #434343;
  margin-left: 150px;
}
.about-section-box a {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  text-align: center;
  background-color: #f2d6af;
  border-radius: 6px;
  padding: 4px;
}

.about-img-1 {
  margin: 80px 0px 0px 200px;
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 1;
}
.about-img-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-img-2 {
  margin: 250px 0px 0px 80px;
  position: absolute;
  width: 500px;
  height: 300px;
  z-index: 1;
}
.about-img-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.read-more-false {
  display: none;
}
@media only screen and (max-width: 400px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size:1.9rem;
    font-weight: 400;
    width: fit-content;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 15px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.1rem;
    text-align: center;
    color: #767474;
  }
  .about-section {
    width: 100%;
    height: 78vh;
  }
  .about-section-canvas {
    width: 100%;
    height: 78vh;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 78vh;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: fit-content;
    padding: 0px;
    align-self: flex-end;
    margin: 20px 0px 40px 20px;
  }
  .about-section-box p {
    margin: 0px;
    font-size: 1.2rem;
    color: #5F5E5E;
    font-family: 'Open Sans';
    font-weight: 400;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 10vh 0px 0px 25px;
    width: 84%;
    height: 24vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 25vh 0px 0px 20px;
    position: absolute;
    width: 50%;
    height: 35vw;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 400px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  .about-container-1 {
    width: 100%;
    height: 100vh;
  }
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size: 2.1rem;
    font-weight: 500;
    width: fit-content;
    color: #5F5E5E;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 10px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.15rem;
    text-align: center;
    color: #767474;
  }
  .about-section {
    width: 100%;
    height: 680px;
  }
  .about-section-canvas {
    width: 100%;
    height:700px;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 700px;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: fit-content;
    padding: 0px;
    margin: 30px;
    align-self: flex-end;
  }
  .about-section-box p {
    margin: 0px;
    color: #767474;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 13vh 0px 0px 50px;
    width: 80%;
    height: 30vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin:26vh 0px 0px 20px;
    position: absolute;
    width: 45%;
    height: 30vh;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 650px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  .about-container-1 {
    width: 100%;
    height: 100vh;
  }
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    width: fit-content;
    color: #5F5E5E;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 10px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    color: #4f4f4f;
  }
  .about-section {
    width: 100%;
    height: 680px;
  }
  .about-section-canvas {
    width: 100%;
    height:700px;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 700px;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: fit-content;
    padding: 0px;
    margin: 30px;
    align-self: flex-end;
  }
  .about-section-box p {
    margin: 0px;
    font-size: 1.5rem;
    color: #5F5E5E;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 13vh 0px 0px 90px;
    width: 75%;
    height: 45vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin:30vh 0px 0px 60px;
    position: absolute;
    width: 50%;
    height: 35vh;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1500px) {
}
