.product-page {
  height: fit-content;
  width: 100%;
  padding-top: 80px;
  background-color: #efe9e3;
  align-content: center;
}
.product-page-container {
  width: 90%;
  left: 5%;
  margin: 0 auto;
  height: fit-content;
  background-color: white;
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
}

.item-details {
  margin-top: 10px;
  margin-left: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.item-details h3 {
  text-align: center;
  font-size: 2.5vw;
  font-family: "Open Sans";
  font-weight: 400;
  color: #434343;
}
.item-details p {
  color: #434343;
  font-size: 1vw;
}
.item-details h4 {
  color: #434343;
  margin-top: 20px;
}
.carousel .thumbs-wrapper {
  align-self: center;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: none;
  transition: none;
}
.frame-row {
  display: flex;
  margin-top: 10px;
  width: 500px;
  justify-content: space-between;
}

.frame-item {
  height: 150px;
  width: 150px;
  background-color: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
}
.frame-item img {
  height: 80%;
  width: 100%;
  object-fit: cover;
}
.frame-item h2 {
  padding: 2px;
  font-weight: 400;
  text-align: center;
  color: #434343;
  font-size: 1vw;
}
.features-list {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.3vw;
  margin: 10px 0px 10px 7px;
  color: #434343;
  display: flex;
}
.uses-list {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.3vw;
  margin: 10px 0px 10px 7px;
  color: #434343;
  display: flex;
}
.feat-li {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.3vw;
  margin: 5px 0px 5px 7px;
  color: #434343;
  display: flex;
}
.features-list svg {
  margin-right: 4px;
}
.uses-list svg {
  margin-right: 4px;
}
#feature-head {
  color: #434343;
  font-size: 1.5vw;
  margin-top: 5px;
  font-weight: 400;
}

.var-vis {
  display: none;
}
.feat-li img {
  height: 20px;
  width: 30px;
}

@media only screen and (max-width: 400px) {
  .product-page {
    height: fit-content;
    min-height: none;
    max-height: none;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: fit-content;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-around;
  }
  .carousel .thumbs-wrapper{
    margin:5px
  }
  .img-container {
    width: 100%;
    height: 40%;
    padding: 0px;
  }
  .item-image {
    height: 280px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    height:fit-content;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    margin-top: 0px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 2.3rem;
    align-self: center;
  }
  .item-details p {
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
    width: 100%;
  }

  .product-page {
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .features-list {
    font-size: 1rem;
    margin: 5px 0px 10px 7px;
    display: flex;
  }
  .uses-list {
    font-size: 1rem;
    margin: 5px 0px 10px 7px;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: .8rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.5rem;
    margin-top:5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 32%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 72%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 400px) {
  .product-page {
    height: fit-content;
    min-height: none;
    max-height: none;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: fit-content;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-around;
  }
  .carousel .thumbs-wrapper{
    margin:5px
  }
  .img-container {
    width: 100%;
    height: 40%;
    padding: 10px;
  }
  .item-image {
    height: 350px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    height:fit-content;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    margin-top: 0px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 2.7rem;
    align-self: center;
  }
  .item-details p {
    text-align: center;
    color: #434343;
    font-size: 1rem;
    width: 100%;
  }

  .product-page {
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .features-list {
    font-size: 1rem;
    margin: 10px 0px 10px 7px;
    display: flex;
  }
  .uses-list {
    font-size: 1rem;
    margin: 10px 0px 10px 7px;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.6rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 32%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 72%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width:900px) {
  .product-page {
    height: fit-content;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
    margin-bottom: 20px;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
  }
  .img-container {
    margin-top: 20px;
    width: 45%;
    height: fit-content;
    padding: 10px;
  }
  .item-image {
    height: 400px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-top: 10px;
    margin-left: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    text-align: center;
    font-size: 2rem;
    font-family: "Open Sans";
    font-weight: 400;
    color: #434343;
  }
  .item-details p {
    color: #434343;
    font-size: 1rem;
  }
  .item-details h4 {
    color: #434343;
    margin-top: 20px;
  }
 
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 30%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 1vw;
  }
  .features-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .uses-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.1rem;
    margin: 10px 0px 10px 7px;
    color: #434343;
    display: flex;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.3rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
}
@media only screen and (min-width: 1500px) {
  .product-page {
    height: fit-content;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
    margin-bottom: 20px;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
  }
  .img-container {
    margin-top: 20px;
    width: 40%;
    height: fit-content;
    padding: 10px;
  }
  .item-image {
    height: 700px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-top: 10px;
    margin-left: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    text-align: center;
    font-size: 2.9rem;
    font-family: "Open Sans";
    font-weight: 400;
    color: #434343;
  }
  .item-details p {
    color: #434343;
    font-size: 1.8rem;
  }
  .item-details h4 {
    color: #434343;
    margin-top: 10px;
    font-size: 1.8rem;
  }
 
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    justify-content: space-between;
  }

  .frame-item {
    height: 250px;
    width: 30%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 1.6rem;
  }
  .features-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.6rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .uses-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.6rem;
    margin: 10px 0px 10px 7px;
    color: #434343;
    display: flex;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.5rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 2rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
}
