.container {
  position: relative;
}
.con-svg{
  width: fit-content;
}

.canvas-contact {
  background-color: #efe9e3;
  width: 100%;
  height: 630px;
}
.box {
  position: absolute;
  width: 90%;
  height: 530px;
  top: 335px;
  left: 50%;
  margin: 0 auto;
  /* margin: 60px 0px 60px 60px;*/
  background-color: white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 20px 5px rgba(0, 0, 0, 0.1);
}

.contact-address {
  margin: 15px 0px 0px 0px;
}
.box h3 {
  width: 150px;
  margin: 10px 0px 0px 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  font-family: "Open Sans";
  border-bottom: solid 2px #e3a44e;
  color: #4f4f4f;
}
.box-child-left h4 {
  margin: 0px 0px 10px 0px;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: italic;
  color: #4f4f4f;
}
.box-child-left {
  margin: 20px 0px 20px 35px;
  width: 43vw;
  border-right: 1px solid #e3a44e;
}
.box-child-right {
  margin: 30px 0px 0px 4vw;
}
.box-container {
  position: absolute;
  display: flex;
  flex-direction: row;
}
iframe {
  width: 35vw;
  height: 200px;
}
.first-last {
  display: flex;
  flex-direction: row;
}
input {
  border: none;
  outline: none;
  height: 40px;
  width: 40vw;
  background-color: #f7e4ca;
  border-radius: 5px;
  margin: 6px 0px 12px 0px;
  caret-color: #bc9662;
  padding: 6px;
  color: #4f4f4f;
  font-size: 1rem;
  text-decoration: none;
}

input:nth-child(2) {
  width: 19.5vw;
}
textarea {
  margin-top: 8px;
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: none;
  background-color: #f7e4ca;
  resize: none;
  outline: none;
  caret-color: #bc9662;
  padding: 6px;
  color: #4f4f4f;
  font-size: 1rem;
}
.name-child {
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.nth-1 {
  margin-left: 15px;
}
.button-submit {
  border: none;
  background-color: #edb76d;
  width: fit-content;
  height: 30px;
  border-radius: 7px;
  padding: 8px;
  color: white;
  outline: none;
  transition: 200ms ease-in-out all;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}
.button-submit:hover {
  background-color: #de9e45;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  transform: translateY(-7px);
}
label {
  color: #4f4f4f;
  font-family: "Open Sans";
  font-size: 0.9rem;
  font-weight: 500;
}
.email {
  color: #4f4f4f;
  text-decoration: none;
  font-size: 1.3vw;
}
.phone {
  color: #4f4f4f;
  text-decoration: none;
  font-size: 2.1vw;
  margin-top: 5px;
  margin-bottom: 5px;
}
.contact-address {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
}
.contact-address p {
  color: #4f4f4f;
  margin-bottom: 10px;
  font-size: 1.2vw;
}

.phone:hover {
  color: #e3a44e;
}
.email i {
  margin-right: 8px;
}
.submitted {
  display: none;
}

@media only screen and (max-width: 650px) {
  .canvas-contact {
    height: 100vh;
    min-height: 1150px;
  }
  .box {
    top: 37em;
    height: 100vh;
    min-height: 1000px;
    padding: 0px;
  }
  .box-container {
    margin-top: 15px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
  }
  iframe {
    margin-left: 10px;
    width: 73vw;
  }
  .first-last {
    flex-direction: row;
  }
  .box-child-left {
    padding-right: 0px;
    border-right: none;
    margin: 0px 0px 0px 35px;
  }
  .box-child-right {
    padding-left: 5px;
  }
  .nth-1 {
    margin-left: 0px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.1rem;
    margin-top: 5px;
    margin-bottom: 3px;
  }
  input {
    width: 100%;
    height: 40px;
  }
  form {
    width: 73vw;
  }
  .name-child {
    flex-direction: column;
  }
  .name-child:nth-child(2) {
    margin-left: 20px;
  }
  input:nth-child(2) {
    width: 100%;
  }
  .contact-address {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 10px;
  }
  .contact-address p {
    font-size: 1.1rem;
  }
  .email {
    font-size: 1.2rem;
  }
  textarea {
    height: 120px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 400px) {
  textarea {
    height: 100px;
  }
  .box-child-left {
    padding-left: 0px;
    margin: 0px 0px 0px 25px;
  }
  .box-child-right {
    margin: 35px 20px 20px 20px;
    padding: 0px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.2rem;
  }
  .email {
    margin-top: 0.8emem;
    font-size: 1rem;
  }
  .contact-address p {
    font-size: 1rem;
    margin-top: 0.5em;
    padding: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 650px) {
  .box {
    top: 30em;
    height: 740px;
    min-height: 740px;
    max-height: 740px;
    padding: 0px;
  }
  .canvas-contact {
    height: 70vh;
    min-height: 900px;
    max-height: 950px;

  }
  .box-container {
    height: 90%;
  }
  input {
    height: 60px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.3rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.2rem;
  }
  .contact-address p {
    font-size: 1.4rem;
    margin: 0px 0px 10px 0px;
    padding: 0;
  }
  .box-child-left {
    margin: 30px 10px 30px 30px;
    width: 50%;
  }
  .box-child-right {
    margin: 0px 0px 0px 0px;
    margin: 10px;
    width: 50%;
  }

  iframe {
    height: 40%;
    width: 95%;
  }
  form {
    width: 100%;
    margin-right: 20px;
  }
  textarea {
    height: 200px;
    width: 93%;
  }
  .button-submit {
    width: fit-content;
    height: 3rem;
    font-size: 1.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .canvas-contact {
    height: 650px;
    min-height: 650px;
    max-height: 650px;
  }
  .box {
    top: 21em;
    height: 540px;
    min-height: 540px;
    max-height: 540px;
  }
  input {
    height: 40px;
  }
  .box-child-right {
    width: 50%;
    margin: 30px 0px 0px 4vw;
  }
  textarea {
    height: 100px;
  }
  .button-submit {
    border: none;
    background-color: #edb76d;
    width: fit-content;
    height: 30px;
    border-radius: 7px;
    padding: 8px;
    color: white;
    outline: none;
    transition: 200ms ease-in-out all;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    font-size: 0.8rem;
  }
  .box-child-right {
    margin-left: 20px;
    width: 50%;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.4rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.3rem;
  }
  .contact-address p {
    font-size: 1.3rem;
    margin: 0px 0px 0px 0px;
    padding: 0;
  }
  iframe {
    height: 40%;
    width: 90%;
  }
  .box-container {
    width: 100%;
    justify-content: space-evenly;
  }
  .button-submit {
    border: none;
    background-color: #edb76d;
    width: fit-content;
    height: 30px;
    border-radius: 7px;
    padding: 8px;
    color: white;
    outline: none;
    transition: 200ms ease-in-out all;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .canvas-contact {
    height: 100vh;
    min-height: 1100px;
  }
  .box {
    top: 35em;
    height: 86vh;
    min-height: 920px;
  }
  input {
    height: 60px;
  }
  textarea {
    height: 270px;
  }
  .box-child-left {
    width: 50%;
  }
  .box-child-right {
    width: 50%;
  }
  form {
    width: 100%;
  }
  .button-submit {
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  iframe {
    height: 50%;
  }
  label {
    color: #4f4f4f;
    font-family: "Open Sans";
    font-size: 1.5rem;
    font-weight: 500;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 2.5rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.9rem;
  }
  .contact-address p {
    font-size: 1.9rem;
    margin: 0px 0px 0px 0px;
    padding: 0;
  }
  .input:nth-child(2) {
    width: 100%;
  }
  .box h3 {
    width: 180px;
    margin: 10px 0px 0px 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    font-family: "Open Sans";
    border-bottom: solid 2px #e3a44e;
    color: #4f4f4f;
  }
  .box-container {
    padding-top: 20px;
    margin-left: 20px;
  }
  .con-svg svg {
    width: 50px;
    height: 50px;
  }
}
