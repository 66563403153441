@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
.navbar {
  font-family: sans-serif;
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 55px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.navbar-link {
  margin: 20px;
  text-decoration: none;
  color: #615e5e;
  border-bottom: solid 2px transparent;
  cursor: pointer;
}
.navbar-link--active a {
  border-bottom: 2px solid #e3a44e;
}
.navbar-link--active--product {
  border-bottom: 2px solid #e3a44e;
}
.navbar-link .col:hover {
  color: #e3a44e;
}
.nav-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar a {
  font-size: 1.2rem;
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  color: #615e5e;
}

.end a:hover {
  color: #e3a44e;
}
.logo {
  float: left;
  height: 48px;
}
.mark {
  height: 30px;
  width: 30px;
}

.navbar-link-contact {
  margin: 10px;
  background-color: #f7e4ca;
  border-radius: 10px;
  padding: 3px;
}
.end {
  display: flex;
  padding-left: 40px;
  padding-right: 10px;
  align-content: center;
}
.hamburger {
  display: none;
}
@media only screen and (max-width: 400px) {
  .navbar {
    flex: row 1;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.1rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 0.7rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 4px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out width;
  }
  .product-toggle-bg h3 {
    font-size: 0.9rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 400px) {
  .navbar {
    flex: row 1;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.2rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.3rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 1rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    font-size: rem;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 1.02rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out width;
  }
  .product-toggle-bg h3 {
    font-size: 1.12rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 1;
  }
}
@media only screen and (min-width: 768px) {
  .navbar {
    flex: row 1;
    justify-content: space-between;
    height: 60px;
    padding: 0px 15px 0px 15px;
  }
  .nav-div {
    display: none;
  }
  .logo {
    height: 55px;
  }
  .hamburger {
    padding: 0px;
    display: inline-block;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
  }
  .hamburger:hover {
    opacity: 1;
  }
  .hamburger.is-active:hover {
    opacity: 1;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #4d4d4d;
  }

  .hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 28px;
    height: 3.5px;
    background-color: #4d4d4d;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
  }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
  }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

  .toggle-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0px 15px 20px;
    background-color: white;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .toggle-email {
    margin: 15px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.3rem;
  }
  .toggle-phone {
    margin: 10px 0px 0px 10px;
    color: #615e5e;
    font-size: 1.4rem;
    outline: none;
  }
  .product-dropdown-invisible ul {
    display: none;
  }
  .product-dropdown-visible ul {
    border-left: 2px dotted #a8a8a8;
    margin-left: 25px;
  }
  .product-dropdown-visible li {
    color: #727272;
    margin-left: 10px;
    font-size: 1.2rem;
    background-color: #f7e4ca;
    border-radius: 5px;
    padding: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .door-button {
    padding: 10px 5px 10px 5px;
    font-size: rem;
    display: flex;
    width: 80vw;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    outline: transparent;
  }
  .toggle-active {
    position: fixed;
    display: inline-block;
    margin-top: 60px;
    z-index: 3;
  }
  .toggle-container {
    position: fixed;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: "Open Sans";
    transition: transform 0.5s;
    z-index: 10;
  }
  #toggle-highlights {
    background-color: #e9e9e9;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  .product-toggle-bg {
    padding: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top: 2px solid #f7e4ca;
  }
  .contact-toggle-bg {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 5px 0px;
  }
  .product-toggle-bg .toggle-contact {
    transition: 200ms ease-in-out;
  }
  .product-toggle-bg h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-left: 5px;
    color: #727272;
  }
  .product-toggle-bg #toggle-hightlights {
    margin-bottom: 1;
  }
}
@media only screen and (min-width: 800px) {
  .navbar {
    font-family: sans-serif;
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 55px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-link {
    margin: 15px;
    text-decoration: none;
    color: #615e5e;
    border-bottom: solid 2px transparent;
    cursor: pointer;
  }
  .navbar-link--active a {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link--active--product {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link .col:hover {
    color: #e3a44e;
  }
  .nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .navbar a {
    font-size: 1.2rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    color: #615e5e;
  }
  
  .end a:hover {
    color: #e3a44e;
  }
  .logo {
    float: left;
    height: 48px;
  }
  .mark {
    height: 30px;
    width: 30px;
  }
  
  .navbar-link-contact {
    margin: 10px;
    background-color: #f7e4ca;
    border-radius: 10px;
    padding: 3px;
  }
  .end {
    display: flex;
    padding-left: 40px;
    padding-right: 10px;
    align-content: center;
  }
  .hamburger {
    display: none;
  }
  .toggle-container{
    display: none;
  }
  .dropdown-content h5 {
    color: #4d4d4d;
    font-size: 1.2rem;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .dropdown-content a {
    width: 100%;
    padding: 5px;
    font-size: 1.08rem;
    font-family: "Open Sans";
    font-weight: 400;
    border-radius: 5px;
    transition: 0.2s ease all;
  }
  .dropdown-content {
    display: none;
    align-self: center;
    margin-left: -50px;
    margin-top: 15px;
    position: absolute;
    width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 160px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: 0.2s 1s;
  }
}
@media only screen and (min-width: 1500px) {
  .navbar {
    font-family: sans-serif;
    position: fixed;
    z-index: 3;
    width: 100vw;
    height: 75px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-link {
    margin: 15px;
    text-decoration: none;
    color: #615e5e;
    border-bottom: solid 2px transparent;
    cursor: pointer;
  }
  .navbar-link--active a {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link--active--product {
    border-bottom: 2px solid #e3a44e;
  }
  .navbar-link .col:hover {
    color: #e3a44e;
  }
  .nav-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .navbar a {
    font-size: 1.85rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    color: #615e5e;
  }
  
  .end a:hover {
    color: #e3a44e;
  }
  .logo {
    float: left;
    height: 60px;
  }
  .mark {
    height: 40px;
    width: 40px;
  }
  
  .navbar-link-contact {
    margin: 10px;
    background-color: #f7e4ca;
    border-radius: 10px;
    padding: 3px;
  }
  .end {
    display: flex;
    padding-left: 40px;
    padding-right: 10px;
    align-content: center;
  }
  .hamburger {
    display: none;
  }
  .toggle-container{
    display: none;
  }

  .dropdown-content h5 {
    color: #4d4d4d;
    font-size: 1.7rem;
    font-family: "Open Sans";
    font-weight: 500;
  }

  .dropdown-content {
    display: none;
    align-self: center;
    margin-left: -50px;
    margin-top: 15px;
    position: absolute;
    width: 400px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 160px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: 0.2s 1s;
  }
  .dropdown-content a {
    width: 100%;
    padding: 5px;
    font-size: 1.45rem;
    font-family: "Open Sans";
    font-weight: 400;
    border-radius: 5px;
    transition: 0.2s ease all;
  }
}
.drop-down {
  position: relative;
  display: inline-block;
  margin: 15px;
}

.drop-down:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  transition-delay: 1s;
}
.dropdown-content {
  padding: 10px 0px 10px 30px;
}

a {
  text-decoration: none;
}
.toggle-not-active {
  display: none;
}
.useability {
  margin-top: -25px;
  height: 30px;
  width: 40%;
  background-color: transparent;
}

.drop-link li {
  margin: 3px;
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  transition: 0.2s ease all;
}
.drop-link li :hover {
  background-color: #f7e4ca;
}
.drop-link {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
/*

.triangle {
  margin-top: -30px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border-bottom: solid 20px white;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}*/

.footer {
  bottom: 0;
  background-color: #2d2d2d;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 0px 200px 0px;
  margin: 0;
  border-top: solid 2px #e3a44e;
}
.footer-component {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 30px 50px 0px 50px;
}
.footer-address i {
  color: #e3a44e;
  margin-right: 4px;
}
.footer-img {
  margin-top: 20px;
  height: 120px;
}
#cp-rights {
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 400;
  color: #8c8c8c;
  text-align: center;
  margin-top: 10px;
}
.footer-component a,
li,
p {
  font-family: "Open Sans";
  font-weight: 400;
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 5px;
}
.footer-component :hover {
  color: #e3a44e;
}
.footer-address {
  display: flex;
  flex-direction: column;
}
.footer-links,
.footer-products a {
  font-size: 1.5rem;
}
.footer-address a {
  margin-bottom: 5px;
}
.socials {
  display: flex;
  width: 45%;
  justify-content: space-between;
}
.foot-prod-row {
  margin-left: 10px;
  display: flex;
}
.foot-prod-col {
  display: flex;
  flex-direction: column;
}
.foot-prod-col-1 {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.foot-prod-col a {
  font-size: 1rem;
  margin: 0;
}
.foot-prod-col-1 a {
  font-size: 1rem;
  margin: 0;
}
@media only screen and (max-width: 400px) {
  .footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px 0px 15px 0px;
  }
  .footer-component {
    padding: 10px 5px 5px 5px;
  }
  .footer-links,
  .footer-products {
    display: none;
  }
  .footer-address p {
    display: none;
  }
  .footer-address {
    margin-top: 20px;
  }
  .footer-img {
    height: 70px;
  }
  #cp-rights {
    font-size: 0.9rem;
  }
  .footer-email,
  .footer-phone {
    font-size: 0.78rem;
  }
  .socials svg {
    width: 25px;
  }
}
@media only screen and (min-width: 400px) {
  .footer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0px 0px 20px 0px;
  }
  .footer-component {
    padding: 10px 5px 5px 5px;
  }
  .footer-links,
  .footer-products {
    display: none;
  }
  .footer-address p {
    display: none;
  }
  .footer-address {
    margin-top: 20px;
  }
  .footer-img {
    height: 90px;
  }
  #cp-rights {
    font-size: 1rem;
  }
  .footer-email,
  .footer-phone {
    font-size: 1.1rem;
  }
  .socials {
    width: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 30%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  .footer-products {
    display: none;
  }
  #cp-rights {
    font-family: monospace;
    font-size: 1.2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    margin: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 1.5rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .foot-prod-col a {
    font-size: 0.5rem;
    margin: 0;
  }
  .foot-prod-col-1 a {
    font-size: 0.5rem;
  }
  .footer-links {
    width: 20%;
  }
  .footer-products {
    width: 20%;
  }
  .footer-links-text a {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 900px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px 10px 0px 10px;
    align-items: center;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 20%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  
  #cp-rights {
    font-family: monospace;
    font-size: 1.2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    align-self: flex-start;
    margin: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 1.5rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
  .foot-prod-col a {
    font-size: 1.1rem;
    margin: 0;
  }
  .foot-prod-col-1 a {
    margin: 0px;
    font-size: 1.1rem;
  }
  .footer-links {
    width: 15%;
  }
  .footer-products {
    align-self: flex-start;
    width: 40%;
  }
  .footer-links-text a {
    font-size: 1.5rem;
  }
  .prod-false{
    display: none;
  }
}
@media only screen and (min-width: 1500px) {
  .footer {
    bottom: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 5px 20px 5px;
    margin: 0;
    border-top: solid 2px #e3a44e;
  }
  .footer-links,
  .footer-products {
    display: block;
  }
  .footer-address p {
    display: block;
  }
  .footer-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px 10px 0px 10px;
    align-items: center;
  }
  .footer-address i {
    color: #e3a44e;
    margin-right: 4px;
  }
  .footer-logo {
    align-self: center;
    margin-top: 0px;
    width: 20%;
  }
  .footer-img {
    margin: 0px;
    width: 90%;
    height: 80%;
  }
  
  #cp-rights {
    font-family: monospace;
    font-size: 2rem;
    font-weight: 400;
    color: #8c8c8c;
    text-align: center;
    margin-top: 10px;
  }
  .footer-component a,
  li,
  p {
    font-size: 1.95rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-decoration: none;
    list-style: none;
    color: white;
    margin: 5px;
  }
  .footer-component :hover {
    color: #e3a44e;
  }
  .footer-address {
    align-self: flex-start;
    margin: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .footer-links,
  .footer-products a {
    font-size: 2.7rem;
  }
  .footer-links .footer-address a {
    margin-bottom: 5px;
  }
  .socials {
    display: flex;
    width: 45%;
    justify-content: space-between;
  }
  .foot-prod-row {
    margin-left: 10px;
  }
  .foot-prod-col {
    display: flex;
    flex-direction: column;
  }
  .foot-prod-col-1 {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
  }
  .foot-prod-col a {
    font-size: 1.8rem;
    margin: 1px;
  }
  .foot-prod-col-1 a {
    margin: 1px;
    font-size: 1.8rem;
  }
  .footer-links {
    width: 15%;
  }
  .footer-products {
    align-self: flex-start;
    width: 40%;
  }
  .footer-links-text a {
    font-size: 2.7rem;
  }
  .prod-false{
    display: none;
  }
  .socials svg {
    width: 55px;
    height: 55px;
  }
}
.canvas-home {
  width: 100%;
  height: 610px;
  background-color: #efe9e3;
}
.whatsapp {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 15px;
  transition: ease 100ms all;
}
.wa-hide {
  display: block;
}
.home-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 610px;
}
.home-contact-bottom {
  font-family: 'Open Sans';
}
.carousel-container {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  margin-top: 55px;
  height: 91%;
  width: 80vw;
  background-color: black;
}
.home-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;
  width: 60vw;
  position: absolute;
  float: left;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-left: 20px;
  padding-top: 20px;
  background-color: white;
}
.carousel .slide {
  background: white;
}
.box-info {
  height: 20%;
  background-color: #edb568;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.box-info-item h2 {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: "Open sans";
  color: white;
  font-weight: 400;
}
.box-info-item {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 15px;
}
.box-info-item img {
  width: 40px;
  height: 40px;
  color: white;
}

.text-carousel h2 {
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel p {
  padding: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}
.text-carousel-1 h2 {
  padding-top: 50px;
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel-1 p {
  padding: 10px;
  margin-top: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}
.text-carousel-2 h2 {
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel-2 p {
  padding: 10px;
  margin-top: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}

.carousel {
  width: 100%;
  height: 100%;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
.carousel-img img{
  width: 100%;
  height: 100%;
  object-fit: fill;

}
.slider-wrapper .axis-vertical {
  height: 100%;
}
.text-carousel {
  align-items: center;
  height: 100%;
  background-color: white;
}
.text-carousel-1 {
  align-items: center;
  height: 100%;
  background-color: white;
}
.text-carousel-2 {
  height: 100%;
  background-color: white;
}
.carousel-button {
  outline: none;
  margin-top: 5px;
  background-color: transparent;
  border: none;
}
.button-column-carousel {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 20px 0px;
  position: absolute;
  right: 0;
  bottom: 0;
}

svg circle:hover path {
  fill: black;
}

/*Home Bottom*/
@font-face {
  font-family: "Nats";
  src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
    format("truetype");
}
.home-bottom {
  width: 100%;
  height: 1000px;
  background-color: #2d2d2d;
}
.home-bottom-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.our-works {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.our-works h3 {
  font-family: "Open Sans";
  font-size: 2.3vw;
  align-self: center;
  font-family: 300;
  color: white;
  border-bottom: 3px solid #e3a44e;
  padding-bottom: 5px;
  width: 13vw;
}
.work-img-scroll {
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 90vw;
  margin-top: 30px;
}
.work-img-1 {
  width: 300px;
  height: 250px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 auto;
  overflow: hidden;

  background-color: #e3a44e;
}
.scroll-left,
.scroll-right {
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
}
.scroll-container {
  display: flex;
}
.why-us {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0px 20px;
}

.why-us h3 {
  font-family: "Open Sans";
  font-size: 2.3vw;
  align-self: center;
  font-family: 300;
  color: white;
  border-bottom: 3px solid #e3a44e;
  padding-bottom: 5px;
  width: 10vw;
}
.why-us h5 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  align-self: center;
  font-family: 300;
  margin-top: 30px;
  color: white;
}

.why-us-det {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.det-container {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.det-row {
  display: flex;
}
.det-row h4 {
  font-family: "Open Sans";
  font-size: 1.5vw;
  margin-left: 10px;
  align-self: center;
  font-family: 600;
  color: white;
  width: 15vw;
}
.det-container p {
  margin-top: 5px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.2vw;
}
.home-contact-bottom {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 20px 0px 0px 0px;
}
.home-cont-row {
  margin-top: 30px;
  display: flex;
  width: 30vw;
}
.home-cont-row h1 {
  font-size: 3vw;
  font-weight: 600;
  margin-right: 10px;
}
.home-contact-bottom a {
  text-decoration: none;
  color: white;
  font-size: 2.2vw;
}
.work-img-1 img {
  height: 250px;
  width: 300px;
  object-fit: cover;
  display: block;
  transition: transform 0.4s;
}
.work-img-1 {
  transition: all 1s;
}
.work-img-1 img:hover {
  transform: scale(1.1);
}
.tel-bottom {
  width: 18vw;
}

/*product section*/

.home-product {
  width: 100%;
  height: 100vh;
  background-color: #2d2d2d;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
}

.home-products-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
}

.home-products-container h3 {
  font-size: 2.3vw;
  color: white;
  align-self: center;
  width: 210px;
  border-bottom: 3px solid #e3a44e;
}
.product-row {
  display: flex;
  margin-top: 10px;
}

.product-preview {
  height: 95%;
  width: 400px;
  margin-left: 50px;
}
.product-preview1 {
  object-fit: cover;
}
.product-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.4s;
}
.product-preview img:hover {
  transform: scale(1.1);
}
.product-grid {
  width: 40%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  padding: 10px;
}
.product-grid-child {
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.product-grid-child h2 {
  color: white;
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: 400;
  text-align: center;
  margin: 2px;
}
.product-preview-container {
  width: 40%;
  height: 96%;
  align-self: center;
  overflow: hidden;
}
.grid-child {
  width: 100%;
  height: 90%;
  overflow: hidden; /* clip the excess when child gets bigger than parent */
}
.grid-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}
.grid-child img:hover {
  transform: scale(1.1);
}
/* about section*/
.about-section {
  width: 100%;
  height: 100vh;
}
.about-section-canvas {
  width: 100%;
  height: 100%;
  background-color: #efe9e3;
}
.about-section-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.about-section-box {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 450px;
  width: 70%;
  background-color: white;
  padding: 40px 60px 0px 70px;
  font-size: 2vw;
}
.about-section-box h3 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  margin-top: 20px;
  margin-left: 20vw;
  color: #434343;
  border-bottom: 2px solid #e3a44e;
  width: 15vw;
}
.about-section-box p {
  font-size: 1.7vw;
  margin-top: 110px;
  color: #434343;
  margin-left: 150px;
}
.about-section-box a {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  text-align: center;
  background-color: #f2d6af;
  border-radius: 6px;
  padding: 4px;
}

.about-img-1 {
  margin: 80px 0px 0px 200px;
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 1;
}
.about-img-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-img-2 {
  margin: 250px 0px 0px 80px;
  position: absolute;
  width: 500px;
  height: 300px;
  z-index: 1;
}
.about-img-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.read-more-false {
  display: none;
}
@media only screen and (max-width: 400px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 210px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .carousel-button svg {
    width: 30px;
    height: 30px;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 0.75rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 0px;
  }
  .box-info-item1 {
    height: 100%;
    width: 34%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
  }
  .box-info-item1 img {
    width: 38px;
    height: 38px;
    color: white;
  }
  .box-info-item1 svg {
    width: 56px;
    height: 56px;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 35px;
    height: 35px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }
  .about-section1 {
    display: none;
  }
  .about-section {
    width: 100%;
    height: 610px;
  }

  .about-section-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .about-section-box {
    bottom: 0;
    right: 0;
    height: 450px;
    width: 70%;
    background-color: white;
    padding: 40px 60px 0px 70px;
    font-size: 2vw;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    margin-top: 20px;
    margin-left: 20vw;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: 15vw;
  }
  .about-section-box p {
    margin-top: 110px;
    color: #767474;
    margin-left: 150px;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 4px;
  }

  .about-img-1 {
    margin: 20px 0px 0px 200px;
    position: absolute;
    width: 500px;
    height: 250px;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 250px 0px 0px 80px;
    position: absolute;
    width: 500px;
    height: 300px;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 100%;
    height: 180px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 2px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 30px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .why-us h5 {
    font-size: 1.35rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
    align-items: center;
  }
  .det-row {
    display: flex;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.25rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .det-container p {
    text-align: center;
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 0.8rem;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-1 img {
    height: 250px;
    width: 300px;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    display: none;
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 0.9rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 400px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 250px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 2px;
  }
  .box-info-item1 {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
  }
  .box-info-item1 img {
    width: 40px;
    height: 40px;
    color: white;
  }
  .box-info-item1 svg {
    width: 55px;
    height: 55px;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 40px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }

  .about-section {
    width: 100%;
    height: 80vh;
  }
  .about-section1 {
    display: none;
  }
  .about-section-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .about-section-box {
    bottom: 0;
    right: 0;
    height: 450px;
    width: 70%;
    background-color: white;
    padding: 40px 60px 0px 70px;
    font-size: 2vw;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.4vw;
    margin-top: 20px;
    margin-left: 20vw;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: 15vw;
  }
  .about-section-box p {
    font-size: 0.8rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin-left: 150px;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 4px;
  }

  .about-img-1 {
    margin: 20px 0px 0px 200px;
    position: absolute;
    width: 500px;
    height: 250px;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 250px 0px 0px 80px;
    position: absolute;
    width: 500px;
    height: 300px;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 80%;
    height: 210px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 32px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 1.4rem;
    align-self: center;
    font-weight: 400;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: center;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 1.8rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.7rem;
    color: white;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 668px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 300px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 2px;
  }
  .box-info-item1 {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
  }
  .box-info-item1 img {
    width: 40px;
    height: 40px;
    color: white;
  }
  .box-info-item1 svg {
    width: 55px;
    height: 55px;
  }

  .text-carousel h2 {
    font-size: 3.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.7vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 3vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 3vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 45px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 80%;
    height: 300px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 40px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    align-self: center;
    font-weight: 400;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: center;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 2.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 2.3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 2rem;
    color: white;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    padding: 15px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 25px;
    -webkit-column-gap: 25px;
            column-gap: 25px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.6rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 900px) {
  .canvas-home {
    display: block;
    width: 100%;
    height: 550px;
    margin-top: 55px;
    position: absolute;
    background-color: #efe9e3;
  }
  .wa-hide {
    display: none;
  }
  .home-container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 560px;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-top: 0;
    height: 100%;
    width: 80%;
    background-color: transparent;
  }
  .home-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 65%;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    margin: 0;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    display: none;
  }
  home .box-info {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
  }
  .box-info-item {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
  }
  .box-info-item svg {
    width: 55px;
    height: 55px;
  }
  .box-info-item img {
    width: 40px;
    height: 40px;
    color: white;
  }

  .text-carousel h2 {
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .about-section {
    display: none;
  }
  .about-section1 {
    padding-top: 50px;
    position: relative;
    display: block;
    width: 100%;
    height: 650px;
  }

  .about-section-container1 {
    position: relative;
    width: 100%;
    height: 600px;
  }
  .about-section-box1 {
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 70%;
    background-color: white;
    padding: 40px;
    font-size: 2vw;
    right: 0;
    bottom: 0;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .about-section-box1 h3 {
    height: 20%;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 3rem;
    margin-right: 0px;
    margin: 30px 100px 20px 0px;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    padding: 20px 0px 0px 0px;
  }
  .about-section-box1 p {
    height: 175px;
    font-size: 1.4rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin: 0px;
    margin-left: 100px;
    padding: 20px 0px 20px 20px;
  }
  .about-section-box1 a {
    font-family: "Open Sans";
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 2px;
  }

  .about-img-1-1 {
    margin: 45px 0px 0px 10vw;
    position: absolute;
    width: 50%;
    height: 320px;
    z-index: 1;
  }
  .about-img-1-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2-1 {
    margin: 250px 0px 0px 4vw;
    position: absolute;
    width: 33%;
    height: 300px;
    z-index: 1;
  }
  .about-img-2-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 30%;
    height: 350px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 45px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 2.3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 2.4rem;
    align-self: center;
    font-weight: 500;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: unset;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    margin-left: 10px;
    align-self: unset;
    font-weight: 600;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: unset;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    text-align: unset;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 2.3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 0px;
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 2rem;
    color: white;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
    padding: 20px;
    width: 100%;
  }

  .product-preview {
    display: block;
    height: 80%;
    width: 400px;
    margin: 0px;
  }

  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: none;
  }
  .product-grid {
    width: 60%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.5rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: block;
    width: 40%;
    height: 50%;
    overflow: hidden;
    padding: 30px;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 1500px) {
  .canvas-home {
    display: block;
    width: 100%;
    height: 800px;
    margin-top: 55px;
    position: absolute;
    background-color: #efe9e3;
  }
  .home-container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 850px;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-top: 0;
    height: 100%;
    width: 80%;
    background-color: black;
  }
  .home-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 65%;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    margin: 0;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    display: none;
  }
  .box-info {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
  }
  .box-info-item {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
  }
  .box-info-item svg {
    width: 80px;
  }
  .box-info-item img {
    width: 50px;
    height: 50px;
    color: white;
  }
  .text-carousel h2 {
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .about-section {
    display: none;
  }
  .about-section1 {
    padding-top: 50px;
    position: relative;
    display: block;
    width: 100%;
    height: 800px;
  }

  .about-section-container1 {
    position: relative;
    width: 100%;
    height: 800px;
  }
  .about-section-box1 {
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 70%;
    background-color: white;
    padding: 40px;
    font-size: 2vw;
    right: 0;
    bottom: 0;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .about-section-box1 h3 {
    height: 20%;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 3.4rem;
    margin-right: 0px;
    margin: 30px 100px 20px 0px;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: flex-end;
    padding: 20px 0px 0px 0px;
  }
  .about-section-box1 p {
    height: 300px;
    font-size: 2.3rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin: 0px;
    margin-left: 100px;
    padding: 0px 0px 20px 20px;
  }
  .about-section-box1 a {
    font-family: "Open Sans";
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 2px;
  }

  .about-img-1-1 {
    margin: 45px 0px 0px 10vw;
    position: absolute;
    width: 50%;
    height: 400px;
    z-index: 1;
  }
  .about-img-1-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2-1 {
    margin: 250px 0px 0px 4vw;
    position: absolute;
    width: 33%;
    height: 400px;
    z-index: 1;
  }
  .about-img-2-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }
  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 95vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 35%;
    height: 450px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 60px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 2.9rem;
    align-self: center;
    font-weight: 500;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
  }
  .det-row {
    display: flex;
    align-self: unset;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 2.3rem;
    margin-left: 10px;
    align-self: unset;
    font-weight: 600;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: unset;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.8rem;
    text-align: unset;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 3.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 3.3rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 0px;
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 3rem;
    color: white;
    align-self: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
    padding: 20px 50px 20px 50px;
    width: 100%;
  }

  .product-preview {
    display: block;
    height: 95%;
    width: 500px;
    margin: 0px;
  }

  .product-preview img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: none;
  }
  .product-grid {
    width: 50%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 2rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: block;
    width: 40%;
    height: 100%;
    overflow: hidden;
    padding: 30px;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
.no-js #loader {
  display: none;
}
.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #FBFBFB;
}
.se-pre-con img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
}
.con-svg{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.canvas-contact {
  background-color: #efe9e3;
  width: 100%;
  height: 630px;
}
.box {
  position: absolute;
  width: 90%;
  height: 530px;
  top: 335px;
  left: 50%;
  margin: 0 auto;
  /* margin: 60px 0px 60px 60px;*/
  background-color: white;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 20px 5px rgba(0, 0, 0, 0.1);
}

.contact-address {
  margin: 15px 0px 0px 0px;
}
.box h3 {
  width: 150px;
  margin: 10px 0px 0px 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  font-family: "Open Sans";
  border-bottom: solid 2px #e3a44e;
  color: #4f4f4f;
}
.box-child-left h4 {
  margin: 0px 0px 10px 0px;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: italic;
  color: #4f4f4f;
}
.box-child-left {
  margin: 20px 0px 20px 35px;
  width: 43vw;
  border-right: 1px solid #e3a44e;
}
.box-child-right {
  margin: 30px 0px 0px 4vw;
}
.box-container {
  position: absolute;
  display: flex;
  flex-direction: row;
}
iframe {
  width: 35vw;
  height: 200px;
}
.first-last {
  display: flex;
  flex-direction: row;
}
input {
  border: none;
  outline: none;
  height: 40px;
  width: 40vw;
  background-color: #f7e4ca;
  border-radius: 5px;
  margin: 6px 0px 12px 0px;
  caret-color: #bc9662;
  padding: 6px;
  color: #4f4f4f;
  font-size: 1rem;
  text-decoration: none;
}

input:nth-child(2) {
  width: 19.5vw;
}
textarea {
  margin-top: 8px;
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: none;
  background-color: #f7e4ca;
  resize: none;
  outline: none;
  caret-color: #bc9662;
  padding: 6px;
  color: #4f4f4f;
  font-size: 1rem;
}
.name-child {
  display: flex;
  flex-direction: column;
}
form {
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.nth-1 {
  margin-left: 15px;
}
.button-submit {
  border: none;
  background-color: #edb76d;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  border-radius: 7px;
  padding: 8px;
  color: white;
  outline: none;
  transition: 200ms ease-in-out all;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}
.button-submit:hover {
  background-color: #de9e45;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
  transform: translateY(-7px);
}
label {
  color: #4f4f4f;
  font-family: "Open Sans";
  font-size: 0.9rem;
  font-weight: 500;
}
.email {
  color: #4f4f4f;
  text-decoration: none;
  font-size: 1.3vw;
}
.phone {
  color: #4f4f4f;
  text-decoration: none;
  font-size: 2.1vw;
  margin-top: 5px;
  margin-bottom: 5px;
}
.contact-address {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
}
.contact-address p {
  color: #4f4f4f;
  margin-bottom: 10px;
  font-size: 1.2vw;
}

.phone:hover {
  color: #e3a44e;
}
.email i {
  margin-right: 8px;
}
.submitted {
  display: none;
}

@media only screen and (max-width: 650px) {
  .canvas-contact {
    height: 100vh;
    min-height: 1150px;
  }
  .box {
    top: 37em;
    height: 100vh;
    min-height: 1000px;
    padding: 0px;
  }
  .box-container {
    margin-top: 15px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
  }
  iframe {
    margin-left: 10px;
    width: 73vw;
  }
  .first-last {
    flex-direction: row;
  }
  .box-child-left {
    padding-right: 0px;
    border-right: none;
    margin: 0px 0px 0px 35px;
  }
  .box-child-right {
    padding-left: 5px;
  }
  .nth-1 {
    margin-left: 0px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.1rem;
    margin-top: 5px;
    margin-bottom: 3px;
  }
  input {
    width: 100%;
    height: 40px;
  }
  form {
    width: 73vw;
  }
  .name-child {
    flex-direction: column;
  }
  .name-child:nth-child(2) {
    margin-left: 20px;
  }
  input:nth-child(2) {
    width: 100%;
  }
  .contact-address {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 10px;
  }
  .contact-address p {
    font-size: 1.1rem;
  }
  .email {
    font-size: 1.2rem;
  }
  textarea {
    height: 120px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 400px) {
  textarea {
    height: 100px;
  }
  .box-child-left {
    padding-left: 0px;
    margin: 0px 0px 0px 25px;
  }
  .box-child-right {
    margin: 35px 20px 20px 20px;
    padding: 0px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.2rem;
  }
  .email {
    margin-top: 0.8emem;
    font-size: 1rem;
  }
  .contact-address p {
    font-size: 1rem;
    margin-top: 0.5em;
    padding: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 650px) {
  .box {
    top: 30em;
    height: 740px;
    min-height: 740px;
    max-height: 740px;
    padding: 0px;
  }
  .canvas-contact {
    height: 70vh;
    min-height: 900px;
    max-height: 950px;

  }
  .box-container {
    height: 90%;
  }
  input {
    height: 60px;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.3rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.2rem;
  }
  .contact-address p {
    font-size: 1.4rem;
    margin: 0px 0px 10px 0px;
    padding: 0;
  }
  .box-child-left {
    margin: 30px 10px 30px 30px;
    width: 50%;
  }
  .box-child-right {
    margin: 0px 0px 0px 0px;
    margin: 10px;
    width: 50%;
  }

  iframe {
    height: 40%;
    width: 95%;
  }
  form {
    width: 100%;
    margin-right: 20px;
  }
  textarea {
    height: 200px;
    width: 93%;
  }
  .button-submit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 3rem;
    font-size: 1.5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .canvas-contact {
    height: 650px;
    min-height: 650px;
    max-height: 650px;
  }
  .box {
    top: 21em;
    height: 540px;
    min-height: 540px;
    max-height: 540px;
  }
  input {
    height: 40px;
  }
  .box-child-right {
    width: 50%;
    margin: 30px 0px 0px 4vw;
  }
  textarea {
    height: 100px;
  }
  .button-submit {
    border: none;
    background-color: #edb76d;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    border-radius: 7px;
    padding: 8px;
    color: white;
    outline: none;
    transition: 200ms ease-in-out all;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    font-size: 0.8rem;
  }
  .box-child-right {
    margin-left: 20px;
    width: 50%;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 1.4rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.3rem;
  }
  .contact-address p {
    font-size: 1.3rem;
    margin: 0px 0px 0px 0px;
    padding: 0;
  }
  iframe {
    height: 40%;
    width: 90%;
  }
  .box-container {
    width: 100%;
    justify-content: space-evenly;
  }
  .button-submit {
    border: none;
    background-color: #edb76d;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    border-radius: 7px;
    padding: 8px;
    color: white;
    outline: none;
    transition: 200ms ease-in-out all;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1500px) {
  .canvas-contact {
    height: 100vh;
    min-height: 1100px;
  }
  .box {
    top: 35em;
    height: 86vh;
    min-height: 920px;
  }
  input {
    height: 60px;
  }
  textarea {
    height: 270px;
  }
  .box-child-left {
    width: 50%;
  }
  .box-child-right {
    width: 50%;
  }
  form {
    width: 100%;
  }
  .button-submit {
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  iframe {
    height: 50%;
  }
  label {
    color: #4f4f4f;
    font-family: "Open Sans";
    font-size: 1.5rem;
    font-weight: 500;
  }
  .phone {
    color: #4f4f4f;
    text-decoration: none;
    font-size: 2.5rem;
  }
  .email {
    margin-top: 0.5em;
    font-size: 1.9rem;
  }
  .contact-address p {
    font-size: 1.9rem;
    margin: 0px 0px 0px 0px;
    padding: 0;
  }
  .input:nth-child(2) {
    width: 100%;
  }
  .box h3 {
    width: 180px;
    margin: 10px 0px 0px 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    font-family: "Open Sans";
    border-bottom: solid 2px #e3a44e;
    color: #4f4f4f;
  }
  .box-container {
    padding-top: 20px;
    margin-left: 20px;
  }
  .con-svg svg {
    width: 50px;
    height: 50px;
  }
}

.canvas-about {
  background-color: #efe9e3;
  width: 100%;
  height: 610px;
}
.about-container {
  height: 610px;
  width: 100%;
  position: absolute;
  top: 0;
}
.about-box {
  position: absolute;
  float: right;
  right: 0;
  bottom: 0;
  width: 80vw;
  height: 450px;
  background-color: white;
  text-align: center;
  padding: 20px 0px 0px 0px;
}
.about-container-1 {
  width: 100%;
  height: 100vh;
}
.about-box h3 {
  font-family: "Open Sans";
  font-size: 2.5vw;
  font-weight: 600;
  margin: 40px 0px 0px 45vw;
  width: 15vw;
  color: #4f4f4f;
  border-bottom: 2px solid #e3a44e;
}
.history {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.history h3 {
  font-family: "Open Sans";
  font-size: 2.5vw;
  font-weight: 600;
  width: 15vw;
  color: #4f4f4f;
  border-bottom: 2px solid #e3a44e;
}
.history p {
  margin: 40px 20px 0px 20px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  color: #4f4f4f;
}
.about-section {
  width: 100%;
  height: 100vh;
}
.about-section-canvas {
  width: 100%;
  height: 100%;
  background-color: #efe9e3;
}
.about-section-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.about-section-box {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 450px;
  width: 70%;
  background-color: white;
  padding: 40px 60px 0px 70px;
  font-size: 2vw;
}
.about-section-box h3 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  margin-top: 20px;
  margin-left: 20vw;
  color: #434343;
  border-bottom: 2px solid #e3a44e;
  width: 15vw;
}
.about-section-box p {
  font-size: 1.5rem;
  margin-top: 110px;
  color: #434343;
  margin-left: 150px;
}
.about-section-box a {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  text-align: center;
  background-color: #f2d6af;
  border-radius: 6px;
  padding: 4px;
}

.about-img-1 {
  margin: 80px 0px 0px 200px;
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 1;
}
.about-img-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-img-2 {
  margin: 250px 0px 0px 80px;
  position: absolute;
  width: 500px;
  height: 300px;
  z-index: 1;
}
.about-img-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.read-more-false {
  display: none;
}
@media only screen and (max-width: 400px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size:1.9rem;
    font-weight: 400;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 15px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.1rem;
    text-align: center;
    color: #767474;
  }
  .about-section {
    width: 100%;
    height: 78vh;
  }
  .about-section-canvas {
    width: 100%;
    height: 78vh;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 78vh;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    align-self: flex-end;
    margin: 20px 0px 40px 20px;
  }
  .about-section-box p {
    margin: 0px;
    font-size: 1.2rem;
    color: #5F5E5E;
    font-family: 'Open Sans';
    font-weight: 400;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 10vh 0px 0px 25px;
    width: 84%;
    height: 24vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 25vh 0px 0px 20px;
    position: absolute;
    width: 50%;
    height: 35vw;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 400px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  .about-container-1 {
    width: 100%;
    height: 100vh;
  }
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size: 2.1rem;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #5F5E5E;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 10px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.15rem;
    text-align: center;
    color: #767474;
  }
  .about-section {
    width: 100%;
    height: 680px;
  }
  .about-section-canvas {
    width: 100%;
    height:700px;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 700px;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    margin: 30px;
    align-self: flex-end;
  }
  .about-section-box p {
    margin: 0px;
    color: #767474;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 13vh 0px 0px 50px;
    width: 80%;
    height: 30vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin:26vh 0px 0px 20px;
    position: absolute;
    width: 45%;
    height: 30vh;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 650px) {
  .canvas-about {
    background-color: #efe9e3;
    width: 100%;
    height: 610px;
  }
  .about-container {
    height: 610px;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .about-box {
    position: absolute;
    float: right;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: 450px;
    background-color: white;
    text-align: center;
    padding: 20px 0px 0px 0px;
  }
  .about-container-1 {
    width: 100%;
    height: 100vh;
  }
  .about-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    font-weight: 600;
    margin: 40px 0px 0px 45vw;
    width: 15vw;
    color: #4f4f4f;
    border-bottom: 2px solid #e3a44e;
  }
  .history {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .history h3 {
    margin-top: 10px;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: #5F5E5E;
    border-bottom: 2px solid #e3a44e;
  }
  .history p {
    margin: 10px 5px 20px 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    color: #4f4f4f;
  }
  .about-section {
    width: 100%;
    height: 680px;
  }
  .about-section-canvas {
    width: 100%;
    height:700px;
    background-color: #efe9e3;
  }
  .about-section-container {
    position: absolute;
    width: 100%;
    height: 700px;
  }
  .about-section-box {
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 400;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    margin: 30px;
    align-self: flex-end;
  }
  .about-section-box p {
    margin: 0px;
    font-size: 1.5rem;
    color: #5F5E5E;
    width: 100%;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px;
  }

  .about-img-1 {
    margin: 13vh 0px 0px 90px;
    width: 75%;
    height: 45vh;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin:30vh 0px 0px 60px;
    position: absolute;
    width: 50%;
    height: 35vh;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 992px) {
}
@media only screen and (min-width: 1500px) {
}

.top-image {
  top: 0;
  width: 100%;
  height: 300px;
  position: fixed;
}
.top-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}
.top-image-container {
  height: 300px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 80px;
  flex-direction: column;
}
.top-image-container h2 {
  align-self: center;
  color: white;
  font-family: "Open Sans";
  font-size: 2.6vw;
  font-weight: 500;
  border-bottom: 3px solid #e3a44e;
  width: 18vw;
}
.top-image-container p {
  margin: 10px;
  align-self: center;
  font-size: 1.5vw;
  font-weight: 500;
  width: 70vw;
  text-align: center;
}
.product-container {
  width: 100%;
  height: 2600px;
  background-color: #efe9e3;
  top: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 0px 0px;
}
.product-wood-container {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-pack-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-door-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-frames-container {
  position: relative;

  width: 100%;
  height: 100px;
  padding: 20px 0px 20px 50px;
}
.product-container h3 {
  font-family: "Open Sans";
  text-align: center;
  font-weight: 600;
  height: 40px;
  width: 400px;
  color: white;
  background-color: #edb76d;
  border-radius: 7px;
  font-size: 2vw;
}
.product-wood-grid {
  margin-top: 50px;
  display: grid;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto;
}
.product-pack-grid {
  margin-top: 50px;
  display: grid;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-template-columns: auto auto auto;
}
.product-frame-grid {
  margin-top: 50px;
  display: grid;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-template-columns: auto auto auto;
}
.product-card {
  background-color: white;
  height: 400px;
  width: 400px;
  box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
}
.product-footer {
  bottom: 0;
}
.product-card img {
  width: 100%;
  height: 85%;
  object-fit: cover;
  transition: 0.5s ease;
}
.product-card h2 {
  padding: 5px;
  text-align: center;
  color: #5a4d4d;
  font-family: "Open Sans";
  font-weight: 500;
}
.product-card img:hover {
  opacity: 0.5;
}
.button-product {
  position: absolute;
  align-self: center;
  border: 2px solid #edb76d;
  z-index: 1;
  top: 1;
}
@media only screen and (max-width: 400px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 1.8rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 0.9rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 1.8rem;
  }
  .product-wood-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-pack-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-card {
    background-color: white;
    height: 300px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 400px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
  }
  .product-wood-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-pack-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto auto;

    grid-template-columns: auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 650px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1.3rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
  }
  .product-wood-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-pack-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 1100px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 2.2rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .top-image-container p {
    margin: 10px;
    align-self: center;
    font-size: 1.3rem;
    font-weight: 300;
    width: 90%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #efe9e3;
    top: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 2.1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-wood-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-pack-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-card {
    background-color: white;
    height: 350px;
    width: 100%;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 1.5rem;
    padding: 5px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}
@media only screen and (min-width: 1500px) {
  .top-image {
    top: 0;
    width: 100%;
    height: 300px;
    position: fixed;
  }
  .top-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
  }
  .top-image-container {
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 80px;
    flex-direction: column;
  }
  .top-image-container h2 {
    align-self: center;
    color: white;
    font-family: "Open Sans";
    font-size: 3rem;
    font-weight: 500;
    border-bottom: 3px solid #e3a44e;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .top-image-container p {
    margin: 15px;
    align-self: center;
    font-size: 1.8rem;
    font-weight: 400;
    width: 80%;
    text-align: center;
  }
  .product-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #efe9e3;
    top: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0px 0px 0px 0px;
  }
  .product-wood-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px;
  }
  .product-pack-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px;
  }
  .product-door-container {
    position: relative;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px;
  }
  .product-frames-container {
    position: relative;

    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 40px;
  }
  .product-container h3 {
    font-family: "Open Sans";
    text-align: center;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    background-color: #edb76d;
    border-radius: 5px;
    font-size: 3rem;
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-wood-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-pack-grid {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-frame-grid {
    margin-top: 50px;
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-template-rows: auto;

    grid-template-columns: auto auto auto;
  }
  .product-card {
    background-color: white;
    height: 40em;
    width: 30vw;
    box-shadow: 0px 6px 39px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .product-footer {
    bottom: 0;
  }
  .product-card img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    transition: 0.5s ease;
  }
  .product-card h2 {
    text-align: center;
    color: #5a4d4d;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 2.3rem;
    padding: 10px;
  }
  .product-card img:hover {
    opacity: 0.5;
  }
  .button-product {
    position: absolute;
    align-self: center;
    border: 2px solid #edb76d;
    z-index: 1;
    top: 1;
  }
}

.product-page {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding-top: 80px;
  background-color: #efe9e3;
  align-content: center;
}
.product-page-container {
  width: 90%;
  left: 5%;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  display: flex;
  padding: 20px;
  justify-content: space-evenly;
}

.item-details {
  margin-top: 10px;
  margin-left: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.item-details h3 {
  text-align: center;
  font-size: 2.5vw;
  font-family: "Open Sans";
  font-weight: 400;
  color: #434343;
}
.item-details p {
  color: #434343;
  font-size: 1vw;
}
.item-details h4 {
  color: #434343;
  margin-top: 20px;
}
.carousel .thumbs-wrapper {
  align-self: center;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: none;
  transition: none;
}
.frame-row {
  display: flex;
  margin-top: 10px;
  width: 500px;
  justify-content: space-between;
}

.frame-item {
  height: 150px;
  width: 150px;
  background-color: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
}
.frame-item img {
  height: 80%;
  width: 100%;
  object-fit: cover;
}
.frame-item h2 {
  padding: 2px;
  font-weight: 400;
  text-align: center;
  color: #434343;
  font-size: 1vw;
}
.features-list {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.3vw;
  margin: 10px 0px 10px 7px;
  color: #434343;
  display: flex;
}
.uses-list {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.3vw;
  margin: 10px 0px 10px 7px;
  color: #434343;
  display: flex;
}
.feat-li {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.3vw;
  margin: 5px 0px 5px 7px;
  color: #434343;
  display: flex;
}
.features-list svg {
  margin-right: 4px;
}
.uses-list svg {
  margin-right: 4px;
}
#feature-head {
  color: #434343;
  font-size: 1.5vw;
  margin-top: 5px;
  font-weight: 400;
}

.var-vis {
  display: none;
}
.feat-li img {
  height: 20px;
  width: 30px;
}

@media only screen and (max-width: 400px) {
  .product-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: none;
    max-height: none;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-around;
  }
  .carousel .thumbs-wrapper{
    margin:5px
  }
  .img-container {
    width: 100%;
    height: 40%;
    padding: 0px;
  }
  .item-image {
    height: 280px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    margin-top: 0px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 2.3rem;
    align-self: center;
  }
  .item-details p {
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
    width: 100%;
  }

  .product-page {
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .features-list {
    font-size: 1rem;
    margin: 5px 0px 10px 7px;
    display: flex;
  }
  .uses-list {
    font-size: 1rem;
    margin: 5px 0px 10px 7px;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: .8rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.5rem;
    margin-top:5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 32%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 72%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 400px) {
  .product-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: none;
    max-height: none;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: space-around;
  }
  .carousel .thumbs-wrapper{
    margin:5px
  }
  .img-container {
    width: 100%;
    height: 40%;
    padding: 10px;
  }
  .item-image {
    height: 350px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    margin-top: 0px;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 2.7rem;
    align-self: center;
  }
  .item-details p {
    text-align: center;
    color: #434343;
    font-size: 1rem;
    width: 100%;
  }

  .product-page {
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
  }
  .features-list {
    font-size: 1rem;
    margin: 10px 0px 10px 7px;
    display: flex;
  }
  .uses-list {
    font-size: 1rem;
    margin: 10px 0px 10px 7px;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.6rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 32%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 72%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width:900px) {
  .product-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
    margin-bottom: 20px;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
  }
  .img-container {
    margin-top: 20px;
    width: 45%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
  }
  .item-image {
    height: 400px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-top: 10px;
    margin-left: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    text-align: center;
    font-size: 2rem;
    font-family: "Open Sans";
    font-weight: 400;
    color: #434343;
  }
  .item-details p {
    color: #434343;
    font-size: 1rem;
  }
  .item-details h4 {
    color: #434343;
    margin-top: 20px;
  }
 
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    justify-content: space-between;
  }

  .frame-item {
    height: 150px;
    width: 30%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 1vw;
  }
  .features-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .uses-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.1rem;
    margin: 10px 0px 10px 7px;
    color: #434343;
    display: flex;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 1.3rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
}
@media only screen and (min-width: 1500px) {
  .product-page {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 80px;
    background-color: #efe9e3;
    align-content: center;
    margin-bottom: 20px;
  }
  .product-page-container {
    width: 90%;
    left: 5%;
    margin: 0 auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: space-evenly;
  }
  .img-container {
    margin-top: 20px;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
  }
  .item-image {
    height: 700px;
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .item-details {
    margin-top: 10px;
    margin-left: 0px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  .item-details h3 {
    text-align: center;
    font-size: 2.9rem;
    font-family: "Open Sans";
    font-weight: 400;
    color: #434343;
  }
  .item-details p {
    color: #434343;
    font-size: 1.8rem;
  }
  .item-details h4 {
    color: #434343;
    margin-top: 10px;
    font-size: 1.8rem;
  }
 
  .frame-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
    height: 10%;
    justify-content: space-between;
  }

  .frame-item {
    height: 250px;
    width: 30%;
    background-color: white;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
  }
  .frame-item img {
    height: 80%;
    width: 100%;
    object-fit: cover;
  }
  .frame-item h2 {
    padding: 2px;
    font-weight: 400;
    text-align: center;
    color: #434343;
    font-size: 1.6rem;
  }
  .features-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.6rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .uses-list {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.6rem;
    margin: 10px 0px 10px 7px;
    color: #434343;
    display: flex;
  }
  .feat-li {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.5rem;
    margin: 5px 0px 5px 7px;
    color: #434343;
    display: flex;
  }
  .features-list svg {
    margin-right: 4px;
  }
  .uses-list svg {
    margin-right: 4px;
  }
  #feature-head {
    color: #434343;
    font-size: 2rem;
    margin-top: 5px;
    font-weight: 400;
  }

  .var-vis {
    display: none;
  }
  .feat-li img {
    height: 20px;
    width: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Open Sans"
  sans-serif;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efe9e3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f7e4ca;
}

*::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  border-radius: 30px;
}

