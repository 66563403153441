.canvas-home {
  width: 100%;
  height: 610px;
  background-color: #efe9e3;
}
.whatsapp {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 15px;
  transition: ease 100ms all;
}
.wa-hide {
  display: block;
}
.home-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 610px;
}
.home-contact-bottom {
  font-family: 'Open Sans';
}
.carousel-container {
  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
  margin-top: 55px;
  height: 91%;
  width: 80vw;
  background-color: black;
}
.home-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65%;
  width: 60vw;
  position: absolute;
  float: left;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-left: 20px;
  padding-top: 20px;
  background-color: white;
}
.carousel .slide {
  background: white;
}
.box-info {
  height: 20%;
  background-color: #edb568;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.box-info-item h2 {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: "Open sans";
  color: white;
  font-weight: 400;
}
.box-info-item {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 15px;
}
.box-info-item img {
  width: 40px;
  height: 40px;
  color: white;
}

.text-carousel h2 {
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel p {
  padding: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}
.text-carousel-1 h2 {
  padding-top: 50px;
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel-1 p {
  padding: 10px;
  margin-top: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}
.text-carousel-2 h2 {
  font-size: 3rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 600;
}
.text-carousel-2 p {
  padding: 10px;
  margin-top: 10px;
  font-size: 1.7rem;
  color: #434343;
  font-family: "Open Sans";
  font-weight: 400;
}

.carousel {
  width: 100%;
  height: 100%;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
.carousel-img img{
  width: 100%;
  height: 100%;
  object-fit: fill;

}
.slider-wrapper .axis-vertical {
  height: 100%;
}
.text-carousel {
  align-items: center;
  height: 100%;
  background-color: white;
}
.text-carousel-1 {
  align-items: center;
  height: 100%;
  background-color: white;
}
.text-carousel-2 {
  height: 100%;
  background-color: white;
}
.carousel-button {
  outline: none;
  margin-top: 5px;
  background-color: transparent;
  border: none;
}
.button-column-carousel {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 20px 0px;
  position: absolute;
  right: 0;
  bottom: 0;
}

svg circle:hover path {
  fill: black;
}

/*Home Bottom*/
@font-face {
  font-family: "Nats";
  src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
    format("truetype");
}
.home-bottom {
  width: 100%;
  height: 1000px;
  background-color: #2d2d2d;
}
.home-bottom-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.our-works {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.our-works h3 {
  font-family: "Open Sans";
  font-size: 2.3vw;
  align-self: center;
  font-family: 300;
  color: white;
  border-bottom: 3px solid #e3a44e;
  padding-bottom: 5px;
  width: 13vw;
}
.work-img-scroll {
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 90vw;
  margin-top: 30px;
}
.work-img-1 {
  width: 300px;
  height: 250px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 auto;
  overflow: hidden;

  background-color: #e3a44e;
}
.scroll-left,
.scroll-right {
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
}
.scroll-container {
  display: flex;
}
.why-us {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0px 20px;
}

.why-us h3 {
  font-family: "Open Sans";
  font-size: 2.3vw;
  align-self: center;
  font-family: 300;
  color: white;
  border-bottom: 3px solid #e3a44e;
  padding-bottom: 5px;
  width: 10vw;
}
.why-us h5 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  align-self: center;
  font-family: 300;
  margin-top: 30px;
  color: white;
}

.why-us-det {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.det-container {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.det-row {
  display: flex;
}
.det-row h4 {
  font-family: "Open Sans";
  font-size: 1.5vw;
  margin-left: 10px;
  align-self: center;
  font-family: 600;
  color: white;
  width: 15vw;
}
.det-container p {
  margin-top: 5px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.2vw;
}
.home-contact-bottom {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 20px 0px 0px 0px;
}
.home-cont-row {
  margin-top: 30px;
  display: flex;
  width: 30vw;
}
.home-cont-row h1 {
  font-size: 3vw;
  font-weight: 600;
  margin-right: 10px;
}
.home-contact-bottom a {
  text-decoration: none;
  color: white;
  font-size: 2.2vw;
}
.work-img-1 img {
  height: 250px;
  width: 300px;
  object-fit: cover;
  display: block;
  transition: transform 0.4s;
}
.work-img-1 {
  transition: all 1s;
}
.work-img-1 img:hover {
  transform: scale(1.1);
}
.tel-bottom {
  width: 18vw;
}

/*product section*/

.home-product {
  width: 100%;
  height: 100vh;
  background-color: #2d2d2d;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
}

.home-products-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
}

.home-products-container h3 {
  font-size: 2.3vw;
  color: white;
  align-self: center;
  width: 210px;
  border-bottom: 3px solid #e3a44e;
}
.product-row {
  display: flex;
  margin-top: 10px;
}

.product-preview {
  height: 95%;
  width: 400px;
  margin-left: 50px;
}
.product-preview1 {
  object-fit: cover;
}
.product-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.4s;
}
.product-preview img:hover {
  transform: scale(1.1);
}
.product-grid {
  width: 40%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 10px;
  row-gap: 10px;
  padding: 10px;
}
.product-grid-child {
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.product-grid-child h2 {
  color: white;
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: 400;
  text-align: center;
  margin: 2px;
}
.product-preview-container {
  width: 40%;
  height: 96%;
  align-self: center;
  overflow: hidden;
}
.grid-child {
  width: 100%;
  height: 90%;
  overflow: hidden; /* clip the excess when child gets bigger than parent */
}
.grid-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}
.grid-child img:hover {
  transform: scale(1.1);
}
/* about section*/
.about-section {
  width: 100%;
  height: 100vh;
}
.about-section-canvas {
  width: 100%;
  height: 100%;
  background-color: #efe9e3;
}
.about-section-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.about-section-box {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 450px;
  width: 70%;
  background-color: white;
  padding: 40px 60px 0px 70px;
  font-size: 2vw;
}
.about-section-box h3 {
  font-family: "Open Sans";
  font-size: 2.8vw;
  margin-top: 20px;
  margin-left: 20vw;
  color: #434343;
  border-bottom: 2px solid #e3a44e;
  width: 15vw;
}
.about-section-box p {
  font-size: 1.7vw;
  margin-top: 110px;
  color: #434343;
  margin-left: 150px;
}
.about-section-box a {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1vw;
  text-align: center;
  background-color: #f2d6af;
  border-radius: 6px;
  padding: 4px;
}

.about-img-1 {
  margin: 80px 0px 0px 200px;
  position: absolute;
  width: 500px;
  height: 250px;
  z-index: 1;
}
.about-img-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-img-2 {
  margin: 250px 0px 0px 80px;
  position: absolute;
  width: 500px;
  height: 300px;
  z-index: 1;
}
.about-img-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.read-more-false {
  display: none;
}
@media only screen and (max-width: 400px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 210px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .carousel-button svg {
    width: 30px;
    height: 30px;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 0.75rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 0px;
  }
  .box-info-item1 {
    height: 100%;
    width: 34%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
  }
  .box-info-item1 img {
    width: 38px;
    height: 38px;
    color: white;
  }
  .box-info-item1 svg {
    width: 56px;
    height: 56px;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 35px;
    height: 35px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }
  .about-section1 {
    display: none;
  }
  .about-section {
    width: 100%;
    height: 610px;
  }

  .about-section-container {
    width: 100%;
    height: fit-content;
  }
  .about-section-box {
    bottom: 0;
    right: 0;
    height: 450px;
    width: 70%;
    background-color: white;
    padding: 40px 60px 0px 70px;
    font-size: 2vw;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.5vw;
    margin-top: 20px;
    margin-left: 20vw;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: 15vw;
  }
  .about-section-box p {
    margin-top: 110px;
    color: #767474;
    margin-left: 150px;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 4px;
  }

  .about-img-1 {
    margin: 20px 0px 0px 200px;
    position: absolute;
    width: 500px;
    height: 250px;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 250px 0px 0px 80px;
    position: absolute;
    width: 500px;
    height: 300px;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 100%;
    height: 180px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 2px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 30px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .why-us h5 {
    font-size: 1.35rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    text-align: center;
    width: fit-content;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
    align-items: center;
  }
  .det-row {
    display: flex;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.25rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    text-align: center;
    width: fit-content;
  }
  .det-container p {
    text-align: center;
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 0.8rem;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
    width: fit-content;
  }
  .work-img-1 img {
    height: 250px;
    width: 300px;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-size: 1.5rem;
    color: white;
    font-family: "Open Sans";
    font-weight: 400;
    align-self: center;
    width: fit-content;
    border-bottom: 2px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    display: none;
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 0.9rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 400px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 250px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 2px;
  }
  .box-info-item1 {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
  }
  .box-info-item1 img {
    width: 40px;
    height: 40px;
    color: white;
  }
  .box-info-item1 svg {
    width: 55px;
    height: 55px;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 40px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }

  .about-section {
    width: 100%;
    height: 80vh;
  }
  .about-section1 {
    display: none;
  }
  .about-section-container {
    width: 100%;
    height: fit-content;
  }
  .about-section-box {
    bottom: 0;
    right: 0;
    height: 450px;
    width: 70%;
    background-color: white;
    padding: 40px 60px 0px 70px;
    font-size: 2vw;
  }
  .about-section-box h3 {
    font-family: "Open Sans";
    font-size: 2.4vw;
    margin-top: 20px;
    margin-left: 20vw;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: 15vw;
  }
  .about-section-box p {
    font-size: 0.8rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin-left: 150px;
  }
  .about-section-box a {
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 4px;
  }

  .about-img-1 {
    margin: 20px 0px 0px 200px;
    position: absolute;
    width: 500px;
    height: 250px;
    z-index: 1;
  }
  .about-img-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2 {
    margin: 250px 0px 0px 80px;
    position: absolute;
    width: 500px;
    height: 300px;
    z-index: 1;
  }
  .about-img-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 80%;
    height: 210px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 32px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 1.4rem;
    align-self: center;
    font-weight: 400;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: center;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    width: fit-content;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 1.8rem;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.7rem;
    color: white;
    align-self: center;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 10px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.1rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 668px) {
  .canvas-home {
    display: none;
  }
  .home-container {
    position: relative;
    top: 55px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 0px;
    height: 300px;
    width: 100%;
    background-color: black;
  }
  .home-box {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 65%;
    width: 60vw;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    width: 100%;
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item1 h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
    padding: 2px;
  }
  .box-info-item1 {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
  }
  .box-info-item1 img {
    width: 40px;
    height: 40px;
    color: white;
  }
  .box-info-item1 svg {
    width: 55px;
    height: 55px;
  }

  .text-carousel h2 {
    font-size: 3.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.7vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 3vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 3vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 600;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5vw;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .carousel-button svg {
    width: 45px;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    height: fit-content;
  }

  svg circle:hover path {
    fill: black;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 80%;
    height: 300px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 40px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    align-self: center;
    font-weight: 400;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: center;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.7rem;
    margin-left: 10px;
    align-self: center;
    font-weight: 500;
    color: white;
    width: fit-content;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 2.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 2.3rem;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 55px;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 2rem;
    color: white;
    align-self: center;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    margin-top: 10px;
    width: 100%;
  }

  .product-preview {
    height: 95%;
    width: 400px;
    margin-left: 50px;
  }
  .product-preview1 {
    object-fit: cover;
  }
  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: scale(1.1);
  }
  .product-grid {
    width: 100%;
    display: grid;
    padding: 15px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 25px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.6rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: none;
    width: 40%;
    height: 96%;
    align-self: center;
    overflow: hidden;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 900px) {
  .canvas-home {
    display: block;
    width: 100%;
    height: 550px;
    margin-top: 55px;
    position: absolute;
    background-color: #efe9e3;
  }
  .wa-hide {
    display: none;
  }
  .home-container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 560px;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-top: 0;
    height: 100%;
    width: 80%;
    background-color: transparent;
  }
  .home-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    width: 65%;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    margin: 0;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    display: none;
  }
  home .box-info {
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
  }
  .box-info-item {
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
  }
  .box-info-item svg {
    width: 55px;
    height: 55px;
  }
  .box-info-item img {
    width: 40px;
    height: 40px;
    color: white;
  }

  .text-carousel h2 {
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 2.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .about-section {
    display: none;
  }
  .about-section1 {
    padding-top: 50px;
    position: relative;
    display: block;
    width: 100%;
    height: 650px;
  }

  .about-section-container1 {
    position: relative;
    width: 100%;
    height: 600px;
  }
  .about-section-box1 {
    position: absolute;
    height: fit-content;
    width: 70%;
    background-color: white;
    padding: 40px;
    font-size: 2vw;
    right: 0;
    bottom: 0;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .about-section-box1 h3 {
    height: 20%;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 3rem;
    margin-right: 0px;
    margin: 30px 100px 20px 0px;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: fit-content;
    align-self: flex-end;
    padding: 20px 0px 0px 0px;
  }
  .about-section-box1 p {
    height: 175px;
    font-size: 1.4rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin: 0px;
    margin-left: 100px;
    padding: 20px 0px 20px 20px;
  }
  .about-section-box1 a {
    font-family: "Open Sans";
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 2px;
  }

  .about-img-1-1 {
    margin: 45px 0px 0px 10vw;
    position: absolute;
    width: 50%;
    height: 320px;
    z-index: 1;
  }
  .about-img-1-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2-1 {
    margin: 250px 0px 0px 4vw;
    position: absolute;
    width: 33%;
    height: 300px;
    z-index: 1;
  }
  .about-img-2-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }

  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 2rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 90vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 30%;
    height: 350px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 45px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 2.3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 2.4rem;
    align-self: center;
    font-weight: 500;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
  }
  .det-row {
    display: flex;
    align-self: unset;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 1.5rem;
    margin-left: 10px;
    align-self: unset;
    font-weight: 600;
    color: white;
    width: fit-content;
    text-align: unset;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1rem;
    text-align: unset;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 2.3rem;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 0px;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 2rem;
    color: white;
    align-self: center;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
    padding: 20px;
    width: 100%;
  }

  .product-preview {
    display: block;
    height: 80%;
    width: 400px;
    margin: 0px;
  }

  .product-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: none;
  }
  .product-grid {
    width: 60%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 30px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 1.5rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: block;
    width: 40%;
    height: 50%;
    overflow: hidden;
    padding: 30px;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
@media only screen and (min-width: 1500px) {
  .canvas-home {
    display: block;
    width: 100%;
    height: 800px;
    margin-top: 55px;
    position: absolute;
    background-color: #efe9e3;
  }
  .home-container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 850px;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-top: 0;
    height: 100%;
    width: 80%;
    background-color: black;
  }
  .home-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    width: 65%;
    position: absolute;
    float: left;
    bottom: 0;
    z-index: 1;
    margin: 0;
    text-align: center;
    margin-left: 20px;
    padding-top: 20px;
    background-color: white;
  }
  .box-info1 {
    display: none;
  }
  .box-info {
    height: fit-content;
    background-color: #edb568;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .box-info-item h2 {
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    font-family: "Open sans";
    color: white;
    font-weight: 400;
  }
  .box-info-item {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
  }
  .box-info-item svg {
    width: 80px;
  }
  .box-info-item img {
    width: 50px;
    height: 50px;
    color: white;
  }
  .text-carousel h2 {
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel p {
    padding: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-1 h2 {
    padding-top: 50px;
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-1 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }
  .text-carousel-2 h2 {
    font-size: 3.5rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 500;
  }
  .text-carousel-2 p {
    padding: 10px;
    margin-top: 10px;
    font-size: 1.8rem;
    color: #434343;
    font-family: "Open Sans";
    font-weight: 400;
  }

  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel-img {
    width: 100%;
    height: 100%;
  }

  .slider-wrapper .axis-vertical {
    height: 100%;
  }
  .text-carousel {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-1 {
    align-items: center;
    height: 100%;
    background-color: white;
  }
  .text-carousel-2 {
    height: 100%;
    background-color: white;
  }
  .carousel-button {
    outline: none;
    margin-top: 5px;
    background-color: transparent;
    border: none;
  }
  .button-column-carousel {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 20px 0px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .about-section {
    display: none;
  }
  .about-section1 {
    padding-top: 50px;
    position: relative;
    display: block;
    width: 100%;
    height: 800px;
  }

  .about-section-container1 {
    position: relative;
    width: 100%;
    height: 800px;
  }
  .about-section-box1 {
    position: absolute;
    height: fit-content;
    width: 70%;
    background-color: white;
    padding: 40px;
    font-size: 2vw;
    right: 0;
    bottom: 0;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  .about-section-box1 h3 {
    height: 20%;
    font-family: "Open Sans";
    font-weight: 500;
    font-size: 3.4rem;
    margin-right: 0px;
    margin: 30px 100px 20px 0px;
    color: #434343;
    border-bottom: 2px solid #e3a44e;
    width: fit-content;
    align-self: flex-end;
    padding: 20px 0px 0px 0px;
  }
  .about-section-box1 p {
    height: 300px;
    font-size: 2.3rem;
    margin-top: 110px;
    color: #5f5e5e;
    margin: 0px;
    margin-left: 100px;
    padding: 0px 0px 20px 20px;
  }
  .about-section-box1 a {
    font-family: "Open Sans";
    color: white;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #f2d6af;
    border-radius: 6px;
    padding: 2px;
  }

  .about-img-1-1 {
    margin: 45px 0px 0px 10vw;
    position: absolute;
    width: 50%;
    height: 400px;
    z-index: 1;
  }
  .about-img-1-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-img-2-1 {
    margin: 250px 0px 0px 4vw;
    position: absolute;
    width: 33%;
    height: 400px;
    z-index: 1;
  }
  .about-img-2-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .read-more-false {
    display: none;
  }
  /*Home Bottom*/
  @font-face {
    font-family: "Nats";
    src: local("Nats") url("/src/assets/fonts/NATS-Regular.ttf")
      format("truetype");
  }
  .home-bottom {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
  }
  .home-bottom-container {
    position: relative;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .our-works {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .our-works h3 {
    font-family: "Open Sans";
    font-size: 3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .work-img-scroll {
    align-self: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 95vw;
    margin-top: 30px;
  }
  .work-img-scroll::-webkit-scrollbar {
    display: none;
  }
  .work-img-1 {
    width: 35%;
    height: 450px;
    margin-right: 10px;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .scroll-left,
  .scroll-right {
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
  }
  .scroll-left,
  .scroll-right,
  svg {
    width: 60px;
  }
  .scroll-container {
    display: flex;
    width: 100%;
  }
  .why-us {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .why-us h3 {
    font-family: "Open Sans";
    font-size: 3rem;
    align-self: center;
    font-weight: 400;
    color: white;
    border-bottom: 3px solid #e3a44e;
    padding-bottom: 5px;
    width: fit-content;
  }
  .why-us h5 {
    font-family: "Open Sans";
    font-size: 2.9rem;
    align-self: center;
    font-weight: 500;
    margin-top: 30px;
    color: white;
    text-align: center;
  }

  .why-us-det {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .det-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 10px;
  }
  .det-row {
    display: flex;
    align-self: unset;
  }
  .det-row h4 {
    font-family: "Open Sans";
    font-size: 2.3rem;
    margin-left: 10px;
    align-self: unset;
    font-weight: 600;
    color: white;
    width: fit-content;
    text-align: unset;
  }
  .det-container p {
    margin-top: 5px;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 1.8rem;
    text-align: unset;
  }
  .home-contact-bottom {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 20px 0px 0px 0px;
  }
  .home-cont-row {
    margin-top: 10px;
    display: flex;
    width: fit-content;
  }
  .home-cont-row h1 {
    font-size: 3.3rem;
    font-weight: 600;
    margin-right: 5px;
    padding: 5px;
  }
  .home-contact-bottom a {
    text-decoration: none;
    color: white;
    font-size: 3.3rem;
    width: fit-content;
  }
  .work-img-1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .work-img-1 {
    transition: all 1s;
  }
  .work-img-1 img:hover {
    transform: scale(1.1);
  }
  .tel-bottom {
    width: fit-content;
  }

  /*product section*/

  .home-product {
    top: 0px;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #2d2d2d;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }

  .home-products-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
  }

  .home-products-container h3 {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 3rem;
    color: white;
    align-self: center;
    width: fit-content;
    border-bottom: 3px solid #e3a44e;
  }
  .product-row {
    display: flex;
    height: fit-content;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
    padding: 20px 50px 20px 50px;
    width: 100%;
  }

  .product-preview {
    display: block;
    height: 95%;
    width: 500px;
    margin: 0px;
  }

  .product-preview img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.4s;
  }
  .product-preview img:hover {
    transform: none;
  }
  .product-grid {
    width: 50%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    column-gap: 30px;
    row-gap: 10px;
  }
  .product-grid-child {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
  }
  .product-grid-child h2 {
    color: white;
    font-size: 2rem;
    font-family: "Open Sans";
    font-weight: 400;
    text-align: center;
    margin: 2px;
  }
  .product-preview-container {
    display: block;
    width: 40%;
    height: 100%;
    overflow: hidden;
    padding: 30px;
  }
  .grid-child {
    width: 100%;
    height: 100%;
    overflow: hidden; /* clip the excess when child gets bigger than parent */
  }
  .grid-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s;
  }
  .grid-child img:hover {
    transform: scale(1.1);
  }
}
.no-js #loader {
  display: none;
}
.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #FBFBFB;
}
.se-pre-con img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
